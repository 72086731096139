<script>
    // import {UTooltip} from "uloc-vue";

    export default {
      name: 'task-followers-tab',
      data () {
        return {
          content: []
        }
      },
      computed: {
        filteredData () {
          let data = this.content
          let sortKey = 'date'
          let order = -1

          // Force order by id, if date equals
          data = data.slice().sort(function (a, b) {
            a = a['id']
            b = b['id']
            return (a === b ? 0 : a > b ? 1 : -1) * -1
          })

          if (sortKey) {
            data = data.slice().sort(function (a, b) {
              a = a[sortKey]
              b = b[sortKey]
              return (a === b ? 0 : a > b ? 1 : -1) * order
            })
          }
          return data
        },
        classes () {
          let css = []

          return css
        },
      },
      mounted () {
      },
      destroyed () {
      },
      methods: {},
      components: {}
    }
</script>

<template>
    <div class="task-followers" :class="classes">
        ...
    </div>
</template>
